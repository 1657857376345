import React from "react"

import { LocalizedLink as Link } from "gatsby-theme-i18n"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"
import { useIntl } from "react-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CardDeck from "../components/card_deck"
import Space from "../components/space"
import FormattedMarkdown from "../components/formatted_markdown"
import { ExchangeRatesBody } from "../components/ExchangeRates"

import bitcoin from "../images/bitcoin.png"
import ethereum from "../images/ethereum.png"
import ripple from "../images/ripple.png"
import binance_coin from "../images/binance_coin.png"
import shiba_inu from "../images/shiba_inu.png"
import vechain from "../images/vechain.png"
import tether from "../images/tether.png"
import bitcoin_cash from "../images/bitcoin_cash.png"
import litecoin from "../images/litecoin.png"
import dogecoin from "../images/dogecoin.png"
import basic_attention_token from "../images/basic_attention_token.png"
import cardano from "../images/cardano.png"
import solana from "../images/solana.png"
import polkadot from "../images/polkadot.png"
import usd_coin from "../images/usd_coin.png"
import uniswap from "../images/uniswap.png"
import chainlink from "../images/chainlink.png"
import algorand from "../images/algorand.png"
import wrapped_bitcoin from "../images/wrapped_bitcoin.png"
import polygon from "../images/polygon.png"
import stellar from "../images/stellar.png"
import cosmos from "../images/cosmos.png"
import filecoin from "../images/filecoin.png"
import axie_infinity from "../images/axie_infinity.png"

const IndexPage = ({ location }) => {
  const intl = useIntl()
  const pageTitle = intl.formatMessage({ id: "Home" })
  return (
    <Layout pageTitle={pageTitle} location={location}>
      <SEO title={pageTitle} />
      <Row>
        <Col xs={12} md={12} xl={5} className="mb-3">
          <Card>
            <Card.Body>
              <Card.Title>
                💱
                <Space />
                {intl.formatMessage({ id: "Exchange" })}
              </Card.Title>
              <Card.Text>
                <FormattedMarkdown id="Home.Exchange" />
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <Buy />
              <Sell />
              <Swap />
            </Card.Footer>
          </Card>
        </Col>
        <Col xs={12} md={6} xl={4} className="mb-3">
          <Card>
            <Card.Body>
              <Card.Title>
                👛
                <Space />
                {intl.formatMessage({ id: "Wallet" })}
              </Card.Title>
              <Card.Text>
                <FormattedMarkdown id="Home.Wallet" />
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <Receive />
              <Send />
            </Card.Footer>
          </Card>
        </Col>
        <Col xs={12} md={6} xl={3} className="mb-3">
          <Card>
            <Card.Body>
              <Card.Title>
                🆔
                <Space />
                {intl.formatMessage({ id: "Identity" })}
              </Card.Title>
              <Card.Text>
                <FormattedMarkdown id="Home.Identity" />
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <MyIdentity />
            </Card.Footer>
          </Card>
        </Col>
      </Row>
      { false && <div className="mb-3">
        <ExchangeRatesBody />
      </div> }
      <CardDeck>
        <IndexCard
          id="Bitcoin"
          img={bitcoin}
          url="https://en.wikipedia.org/wiki/Bitcoin"
        />
        <IndexCard
          id="Ethereum"
          img={ethereum}
          url="https://en.wikipedia.org/wiki/Ethereum"
        />
        <IndexCard
          id="Binance Coin"
          img={binance_coin}
          url="https://en.wikipedia.org/wiki/Binance"
        />
        <IndexCard
          id="Solana"
          img={solana}
          url="https://en.wikipedia.org/wiki/Solana_(blockchain_platform)"
        />
        <IndexCard
          id="Tether"
          img={tether}
          url="https://en.wikipedia.org/wiki/Tether_(cryptocurrency)"
        />
        <IndexCard
          id="Cardano"
          img={cardano}
          url="https://en.wikipedia.org/wiki/Cardano_(blockchain_platform)"
        />
        <IndexCard
          id="Ripple"
          img={ripple}
          url="https://en.wikipedia.org/wiki/Ripple_(payment_protocol)"
        />
        <IndexCard
          id="Polkadot"
          img={polkadot}
          url="https://en.wikipedia.org/wiki/Polkadot_(cryptocurrency)"
        />
        <IndexCard
          id="Dogecoin"
          img={dogecoin}
          url="https://en.wikipedia.org/wiki/Dogecoin"
        />
        <IndexCard
          id="USD Coin"
          img={usd_coin}
          url="https://en.wikipedia.org/wiki/USD_Coin"
        />
        <IndexCard
          id="Shiba Inu"
          img={shiba_inu}
          url="https://en.wikipedia.org/wiki/Shiba_Inu_(cryptocurrency)"
        />
        <IndexCard
          id="Uniswap"
          img={uniswap}
          url="https://en.wikipedia.org/wiki/Uniswap"
        />
        <IndexCard
          id="Chainlink"
          img={chainlink}
          url="https://en.wikipedia.org/wiki/Chainlink_(blockchain)"
        />
        <IndexCard
          id="Wrapped Bitcoin"
          img={wrapped_bitcoin}
          url="https://wbtc.network"
        />
        <IndexCard
          id="Litecoin"
          img={litecoin}
          url="https://en.wikipedia.org/wiki/Litecoin"
        />
        <IndexCard
          id="Polygon"
          img={polygon}
          url="https://polygon.technology"
        />
        <IndexCard
          id="Algorand"
          img={algorand}
          url="https://en.wikipedia.org/wiki/Algorand_(cryptocurrency_platform)"
        />
        <IndexCard
          id="Bitcoin Cash"
          img={bitcoin_cash}
          url="https://en.wikipedia.org/wiki/Bitcoin_Cash"
        />
        <IndexCard
          id="VeChain"
          img={vechain}
          url="https://en.bitcoinwiki.org/wiki/Vechain"
        />
        <IndexCard
          id="Axie Infinity"
          img={axie_infinity}
          url="https://en.wikipedia.org/wiki/Axie_Infinity"
        />
        <IndexCard
          id="Stellar"
          img={stellar}
          url="https://en.wikipedia.org/wiki/Stellar_(payment_network)"
        />
        <IndexCard id="Cosmos" img={cosmos} url="https://cosmos.network" />
        <IndexCard
          id="Filecoin"
          img={filecoin}
          url="https://en.wikipedia.org/wiki/Filecoin"
        />
        <IndexCard
          id="Basic Attention Token"
          img={basic_attention_token}
          url="https://en.wikipedia.org/wiki/Brave_(web_browser)"
        />
      </CardDeck>
    </Layout>
  )
}

const IndexCard = props => {
  const intl = useIntl()
  return (
    <Card>
      <Card.Img src={props.img} />
      <Card.Body>
        <Card.Title>{intl.formatMessage({ id: props.id })}</Card.Title>
        <Card.Text>
          <FormattedMarkdown id={"Home." + props.id} />
        </Card.Text>
      </Card.Body>
      <Card.Footer>
        { false && <Buy /> }
        { false && <Sell /> }
        <Swap />
        <More
          to={intl.formatMessage({
            id: props.url,
          })}
        />
      </Card.Footer>
    </Card>
  )
}

const Buy = () => {
  const intl = useIntl()
  return (
    <div className="btn btn-primary mr-1 mb-1 disabled">
      {intl.formatMessage({ id: "Buy" })}
    </div>
  )
}

const Sell = () => {
  const intl = useIntl()
  return (
    <div className="btn btn-primary mr-1 mb-1 disabled">
      {intl.formatMessage({ id: "Sell" })}
    </div>
  )
}

const Swap = () => {
  const intl = useIntl()
  return (
    <Link to="/exchange_swap" className="btn btn-primary mr-1 mb-1">
      {intl.formatMessage({ id: "Swap" })}
    </Link>
  )
}

const Receive = () => {
  const intl = useIntl()
  return (
    <Link to="/wallet_receive" className="btn btn-success mr-1 mb-1">
      {intl.formatMessage({ id: "Receive" })}
    </Link>
  )
}

const Send = () => {
  const intl = useIntl()
  return (
    <Link to="/wallet_send" className="btn btn-danger mr-1 mb-1">
      {intl.formatMessage({ id: "Send" })}
    </Link>
  )
}

const MyIdentity = () => {
  const intl = useIntl()
  return (
    <Link to="/my_identity" className="btn btn-warning mr-1 mb-1">
      {intl.formatMessage({ id: "My Identity" })}
    </Link>
  )
}

const More = ({ to }) => {
  const intl = useIntl()
  return (
    <a href={to} target="_blank" className="btn btn-secondary mr-1 mb-1">
      {intl.formatMessage({ id: "More" })}
    </a>
  )
}

export default IndexPage
